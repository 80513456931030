<template>
  <!-- 地址新增，编辑 修改 -->
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="$fun.routerToPage('/user/deliverySite')"
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("配送管理") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
    <template v-slot:right-text v-if="showdelete">
      <div class="top-title" @click="deleted">{{ $fanyi("删除") }}</div>
    </template>
  </headBackBar>

  <!-- 内容区域 -->
  <!-- 表单区域 -->

  <div class="content">
    <div class="form">
      <van-form ref="addform">
        <van-cell-group inset>
          <!-- 地址名称 -->
          <van-field
            v-model.trim="form.title"
            label-width="100%"
            required
            name="title"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入地址名称'),
                trigger: 'onBlur',
              },
            ]"
            :label="$fanyi('地址名称') + ' :'"
          />
          <!-- 氏名 -->
          <van-field
            v-model.trim="form.name"
            required
            type="text"
            name="name"
            :label="$fanyi('氏名') + ':'"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入氏名'),
                trigger: 'onBlur',
              },
            ]"
          />
          <!-- 罗马氏名 -->
          <van-field
            v-model.trim="form.name_rome"
            label-width="100%"
            type="text"
            name="name_rome"
            :label="$fanyi('ローマ字（氏名）：')"
          />
          <!-- 公司名 -->
          <van-field
            v-model.trim="form.company"
            label-width="100%"
            type="text"
            name="company"
            :label="$fanyi('公司名') + '：'"
            :rules="[
              {
                trigger: 'onBlur',
                validator: wordLimit,
              },
            ]"
          />

          <!-- 住所 -->
          <van-field
            v-model.trim="form.address"
            required
            :rules="[
              { required: true, message: $fanyi('请输入住所') },
              {
                trigger: 'onBlur',
                validator: validatoraddress,
              },
            ]"
            label-width="100%"
            type="text"
            name="address"
            :label="$fanyi('住所') + '：'"
          />
          <!-- 罗马住所 -->
          <van-field
            v-model.trim="form.address_rome"
            label-width="100%"
            type="text"
            name="address_rome"
            :label="$fanyi('ローマ字（住所）：')"
          />
          <!-- 邮编 -->
          <van-field
            v-model.trim="form.zip"
            required
            label-width="100%"
            type="text"
            :rules="[
              {
                required: true,
                message: $fanyi(' 请输入邮编'),
                trigger: ['onBlur', 'onChange'],
              },
              {
                trigger: 'onBlur',
                validator: validatorzip,
              },
            ]"
            name="zip"
            :label="$fanyi('邮编') + '：'"
          />
          <!-- 手机 -->
          <van-field
            v-model.trim="form.mobile"
            required
            label-width="100%"
            :rules="[
              {
                required: true,
                message: $fanyi('手机号为空'),
                trigger: 'onBlur',
              },
            ]"
            type="number"
            name="mobile"
            :label="$fanyi('手机：')"
          />
          <!-- 座机 -->
          <van-field
            v-model.trim="form.tel"
            label-width="100%"
            type="text"
            name="tel"
            :label="$fanyi('座机：')"
          />
          <!-- 法人番号 -->
          <van-field
            v-model.trim="form.corporate_name"
            label-width="100%"
            type="text"
            name="corporate_name"
            :label="$fanyi('法人番号：')"
          />
          <!--  实时账户-->
          <van-field
            v-model.trim="form.account"
            label-width="100%"
            type="number"
            name="account"
            :label="$fanyi('リアルタイム口座：')"
            :rules="[
              {
                validator: validatoraccount,
              },
            ]"
          />
          <!-- 标椎代码 -->
          <van-field
            v-model.trim="form.standard_code"
            label-width="100%"
            type="number"
            name="standard_code"
            :label="$fanyi('標準コード：')"
          />
        </van-cell-group>
      </van-form>
    </div>

    <!-- 保存 -->
    <div class="baocun" @click="save">{{ $fanyi("保存") }}</div>
  </div>
</template>
<script setup>
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref, watch } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { Toast } from "vant";
import { useStore } from "vuex";
const { proxy } = getCurrentInstance();
const route = useRoute();
const store = useStore();
const step = ref(1);
const addform = ref("");
// 进行判断是从修改还是偏激
const showdelete = ref(false);

// 表单数据
const form = ref({
  title: "住所01", // 地址标题，
  name: "", // 姓名
  name_rome: "", //姓名罗马字
  company: "", //公司名

  corporate_name: "", // 法人番号
  address: "", //详细地址
  address_rome: "", // 详细地址罗马字
  zip: "", //邮箱
  mobile: "", //手机号
  tel: "", //座机号
  account: "", //实时账户
  standard_code: "", //标椎代码
});
if (route.query.type == "add") {
  showdelete.value = false;
} else if (route.query.type == "edit") {
  showdelete.value = true;
  form.value = JSON.parse(sessionStorage.getItem("Addressuser"));
  // form.value.title = proxy.$store.state.Addressuser.title
  // form.value.name = proxy.$store.state.Addressuser.name
  // form.value.name_rome = proxy.$store.state.Addressuser.name_rome
  // form.value.company = proxy.$store.state.Addressuser.company
  // form.value.corporate_name = proxy.$store.state.Addressuser.corporate_name
  // form.value.address = proxy.$store.state.Addressuser.address
  // form.value.address_rome = proxy.$store.state.Addressuser.address_rome
  // form.value.zip = proxy.$store.state.Addressuser.zip
  // form.value.mobile = proxy.$store.state.Addressuser.mobile
  // form.value.tel = proxy.$store.state.Addressuser.tel
  // form.value.standard_code = proxy.$store.state.Addressuser.standard_code
  // form.value.id = proxy.$store.state.Addressuser.id
}
// 校验字数
const wordLimit = (val) => {
  if (!/^.{0,255}$/.test(val)) {
    return proxy.$fanyi("最多输入255个字符");
  }
};
// 姓氏校验
const validatorname = (val) => {
  if (val.length > 24) {
    return proxy.$fanyi("姓氏长度不能超过24个字符");
  }
};
// 住所校验
const validatoraddress = (val) => {
  if (val.length > 255) {
    return proxy.$fanyi("住所长度不能超过255个字符");
  }
};
// 邮编校验
const validatorzip = (val) => {
  if (!/^[0-9 -]{7,8}$/.test(val)) {
    return proxy.$fanyi("格式不正确");
  }
};
// 账户校验
const validatoraccount = (val) => {
  if (val && !/^(\w){14}$/.test(val)) {
    return proxy.$fanyi("リアルタイム口座输入错误");
  }
};
//  编辑地址可以删除  删除地址
const deleted = async () => {
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  const res = await proxy.$api.userAddressDelete({
    id: [form.value.id],
  });
  if (res.code !== 0) {
    Toast.clear();
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  Toast.clear();
  Toast.success(proxy.$fanyi("删除成功"));
  proxy.$fun.routerToPage("/user/deliverySite");
};

// 保存地址
const save = async () => {
  try {
    await addform.value.validate();
    console.log();

    // 判断 account 符不符合规范
    if (form.value.account != "" && !/[0-9]{14}$/.test(form.value.account)) {
      Toast(proxy.$fanyi("リアルタイム口座请输入14位纯数字"));
      return;
    }

    // 进行判断是新增还是编辑
    if (route.query.type == "add") {
      Toast.loading({
        duration: 0,
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      form.value.mobile = form.value.mobile.toString();
      const res = await proxy.$api.userAddressUpdate(form.value);
      if (res.code !== 0) {
        Toast.fail(proxy.$fanyi(res.msg));
        Toast.clear();
        return;
      }
      Toast.clear();
      // showdelete.value = false
      Toast.success(proxy.$fanyi("添加成功"));
      proxy.$fun.routerToPage("/user/deliverySite");
    }
    if (route.query.type == "edit") {
      Toast.loading({
        duration: 0,
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      form.value.mobile = form.value.mobile.toString();
      const res = await proxy.$api.userAddressUpdate(form.value);
      if (res.code !== 0) {
        Toast.fail(proxy.$fanyi(res.msg));
        Toast.clear();
        return;
      }
      Toast.clear();
      // showdelete.value = false
      Toast.success(proxy.$fanyi("修改成功"));
      proxy.$fun.routerToPage("/user/deliverySite");
    }
  } catch (e) {}
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.top-title {
  width: 55px;

  font-size: 24px;

  font-weight: 400;
  color: #000000;
}

:deep().van-field__error-message {
  padding-left: 5px;
  font-size: 22px;
}

.content {
  width: 100%;
  background: #f6f6f6;
  padding: 20px 30px 60px 30px;

  .form {
    padding: 30px;
    padding-top: 36px;
    width: 690px;
    min-height: 1620px;
    background: #ffffff;
    border-radius: 6px;
    font-size: 24px;

    :deep().van-cell {
      display: block;
      margin: 0;
      font-size: 24px;
      padding: 0;

      input {
        font-size: 24px;
        padding-left: 29px;
        width: 630px;
      }
      &:after {
        display: none;
      }
    }

    .van-cell-group {
      margin: 0;
      padding: 0;
    }

    :deep().van-cell__value {
      height: 110px;
    }

    :deep().van-cell__title {
      height: 38px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      font-size: 24px;

      font-weight: 400;
      color: #000000;
    }
  }

  .baocun {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;

    font-weight: 400;
    margin-top: 30px;
    color: #fff;
  }
}
</style>
